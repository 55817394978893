<template>
  <div>
    <van-cell-group title="基础信息">
      <van-cell title="单据类型" :value="model.CAT_TXT" />
      <van-cell title="单号" :value="model.SN" />
      <van-cell title="提交人" :value="model.SUB_NAME" />
      <van-cell title="状态" :value="model.STA_TXT" />
      <van-cell title="步骤" :value="model.STEP_DESC" v-if="model.STA == 10" />
      <van-cell title="日期">
        {{ model.CREATED_DT | datetime('YYYY-MM-DD') }}
      </van-cell>
      <van-cell title="分类" :value="model.CAT_TXT" />
      <van-cell :title="model.TITLE">
        <template #default>￥{{ model.RAMT }} 元</template>
      </van-cell>
      <van-cell v-if="model.CLASS_NAME" title="所属班级" :value="model.CLASS_NAME" />
      <van-cell v-if="model.LOCATION" title="所属位置" :value="model.LOCATION" />
    </van-cell-group>
    <div v-if="model.STA == 10 && model.STEP_TEA_ID == model.UID && [201, 121].indexOf(model.STEP_IDX) >= 0">
      <van-cell-group v-if="model.STEP_IDX == 121" title="物品列表">
        <van-field v-for="(it, idx) in model.gridg" :key="idx" :label="it.ST_NAME" type="number" input-align="right"
          label-width="20em" v-model="it.RAMT" @input="getSummaries">
          <template #label>
            {{ it.ST_NAME }} ({{ it.CNT }} {{ it.ST_UNIT }})<span v-if="it.STA_TXT" style="color: red;">{{ it.STA_TXT
              }}</span>
            <div v-if="it.DESC" style="font-size: 12px; line-height: 14px; color:#969799">{{ it.DESC }}</div>
          </template>
          <template #extra>
            <span style="text-decoration: line-through; color: #ccc; margin-left: 10px">￥{{ it.AMT }}</span>
          </template>
        </van-field>
      </van-cell-group>
      <van-cell-group v-else-if="model.STEP_IDX == 201" title="物品列表">
        <van-field v-for="(it, idx) in model.gridg" :key="idx" :label="it.ST_NAME" type="number" input-align="right"
          label-width="20em" v-model="it.CNT">
          <template #label>
            {{ it.ST_NAME }} 【{{ it.ST_UNIT }}】<span v-if="it.STA_TXT" style="color: red;">{{ it.STA_TXT }}</span>
          </template>
        </van-field>
      </van-cell-group>
    </div>
    <van-cell-group title="物品列表" v-else>
      <van-cell v-for="(it, idx) in model.gridg" :key="idx" :title="it.ST_NAME">
        ￥{{ it.RAMT ? it.RAMT.toFixed(2) : it.AMT.toFixed(2) }} 元
        <template #label>
          {{ it.CNT }} ({{ it.ST_UNIT }}) <span v-if="it.STA_TXT" style="color: red;">{{ it.STA_TXT }}</span>
          <span v-if="it.DESC"> | {{ it.DESC }}</span>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="审核记录">
      <van-cell v-for="(it, idx) in model.gridv" :key="idx">
        <template #title>
          {{ it.TEA_NAME }} ({{ it.STEP_NAME }})
        </template>
        <template #label>
          {{ it.VTIME | datetime('YYYY-MM-DD') }} |
          {{ it.DESC }}
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="我的意见" v-if="model.STA == 10 && model.STEP_TEA_IDS.indexOf(`,${model.UID},`) >= 0">
      <van-field type="text" v-model="model.DESC" placeholder="请输入您拒绝的理由" label="理由" />
      <van-row :gutter="10" style="padding: 10px 20px" :loading="ret.loading">
        <van-col :span="12">
          <van-button type="danger" icon="circle" block @click="refuse">拒绝</van-button>
        </van-col>
        <van-col :span="12">
          <van-button type="primary" icon="success" block @click="agree">同意</van-button>
        </van-col>
      </van-row>
    </van-cell-group>
    <div v-if="model.STA == 10 || model.STA == 0" style="margin: 15px">
      <van-button v-if="model.UID == model.SUB_ID || model.Show == true" type="warning" size="large" icon="cross" block
        @click="cancel">撤消单据</van-button>
    </div>
    <div style="margin: 15px">
      <van-button plain type="default" size="large" icon="revoke" block @click="goback">返 回</van-button>
      <van-button v-if="model.STA == 20 || model.STA == 0 || model.STA == 30" type="primary" size="large" block
        @click="goEdit" style="margin: 5px 0;">编辑</van-button>
      <van-button v-if="model.CAT == 100 && model.STA == 90" type="primary" size="large" icon="cart-o" block
        @click="toNew" style="margin-top: 15px">去 领 用</van-button>
    </div>
    <van-overlay :show="ret.loading" style="text-align: center; padding-top: 300px;">
      <van-loading size="48" type="spinner" color="#fff" vertical>处理中,请稍候 。。。</van-loading>
    </van-overlay>
  </div>
</template>

<script>
  import { Dialog } from 'vant';
  export default {
    name: "edit",
    data() {
      return {
        model: {
          gridg: [],
          gridv: [],
        },
        ret: {
          loading: false
        }
      }
    },
    created() {
      let id = this.$route.query.ID;
      this.get(id);
    },
    methods: {
      get(id) {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/Mobile/MAT/MVActApi/Get",
          data: { ID: id },
          completed: function (m) {
            m.DATA.gridg = JSON.parse(m.DATA.GBODY);
            m.DATA.gridg.map(i => {
              m.DATA.StockNotEnoughStockIds.map(j => {
                if (i.ST_ID == j) {
                  i.STA_TXT = '(库存不足)'
                }
              })
            })
            m.DATA.gridv = JSON.parse(m.DATA.VBODY);
            self.model = m.DATA;
            let user = self.whale.user.get();
            self.model.UID = user.ID;
            self.model.DESC = '库存不足'

            let arr = m.DATA.STEP_TEA_IDS.split(',')
            arr.map(i => {
              if (i && i == user.ID) {
                self.model.Show = true
              }
            })
          }
        })
      },
      cancel() {
        let self = this;
        Dialog.confirm({
          title: "撤消",
          message: "真的要撤消这张采购单吗？"
        }).then(() => {
          self.ret.loading = true;
          self.whale.remote.getResult({
            url: "/api/Mobile/MAT/MVActApi/Cancel",
            data: { ID: self.model.ID },
            finally() { self.ret.loadin = false; },
            completed: function () {
              self.opCompleted();
            }
          })
          // self.opCompleted();
        }).catch(() => { })
      },
      refuse() {
        let self = this;
        self.ret.loading = true;
        this.whale.remote.getResult({
          url: "/api/Mobile/MAT/MVActApi/Refuse",
          data: {
            ID: self.model.ID,
            DESC: self.model.DESC
          },
          finally() { self.ret.loading = false; },
          completed: function () {
            self.opCompleted();
          }
        })
      },
      agree() {
        let self = this;
        let url = "/api/Mobile/MAT/MVActApi/agree";
        let idx = self.model.STEP_IDX;
        let idx_type = self.model.STEP_IDX_TYPE;
        if (idx == 121) {
          url += "121";
        } else if (idx == 122) {
          url += "122";
        } else if (idx == 201) {
          url += "201";
        } else {
          if (idx_type == 1 || idx_type == 2) {
            //采购审批流程
            url += "110";
          }
        }

        //采购人，调整实际金额
        if (idx == 121 || idx == 201) {
          let ramt = 0;
          self.model.gridg.map(o => {
            o.RAMT = Number(o.RAMT);
            ramt += o.RAMT
          });
          self.model.GBODY = JSON.stringify(self.model.gridg);
          self.model.RAMT = ramt;
        }
        self.ret.loading = true;
        this.whale.remote.getResult({
          url: url,
          data: self.model,
          finally() { self.ret.loading = false; },
          completed: function () {
            self.opCompleted();
          }
        })
      },
      goEditPage() {
        this.$router.push({ path: "/mat/valid/edit2", query: { cat: this.$route.query.ID } });
      },
      opCompleted() {
        let self = this;
        self.ret.loading = false;
        // self.$dialog.alert({
        //   title: "完成",
        //   message: "记录已成功提交！",
        //   theme: "round",
        // }).then(() => { self.goback(); })
        self.$dialog.confirm({
          title: '撤销成功',
          message: '是否去编辑物品页面?',
          confirmButtonText: "去编辑",
          cancelButtonText: "返回"
        }).then(() => {
          self.get(self.$route.query.ID);
        }).catch(() => {
          self.goback();
        });
      },
      goback() {
        let back = this.$route.query.back;
        if (back == "home") this.$router.push("/")
        else
          this.$router.go(-1);
      },
      toNew() {
        this.$router.push({ path: "/mat/valid/new", query: { cat: 200, sid: this.model.ID } });
      },
      getSummaries() {
        let ramt = 0
        this.model.gridg.map(o => ramt += Number(o.RAMT));
        this.model.RAMT = ramt
      },
      goEdit() {
        sessionStorage.setItem('editMat', JSON.stringify(this.model))
        this.$router.push({ path: '/mat/valid/edit2', query: { cat: this.model.CAT } })
      }
    }
  }
</script>
<style scoped></style>